import React from 'react'
import {StaticImage} from "gatsby-plugin-image"
import {Link} from 'gatsby'


const Footer = ({locale}) => {
    return (
        <footer className="w-full mt-10 md:mt-20 bg-primary text-white">
            <div className="flex flex-col justify-between lg:flex-row divide-white-200 divide-y lg:divide-y-0 divide-x-0 lg:divide-x m-auto lg:max-w-6xl p-3 lg:p-0">
                <div className="flex flex-col flex-1 justify-between sm:justify-center lg:justify-start md:flex-row my-2 gap-4 lg:gap-1 place-content-around">
                    <div className="flex  md:flex-col self-center mt-1">
                        <div className="logo nav-link lg:self-start self-center md:m-3">
                            <StaticImage src="../images/logo-obra-ya.png" alt="Obra Ya" layout="constrained"
                                width={182}
                                height={57}
                                className="m-auto w-4/5 h-auto md:w-full"
                                imgClassName="h-auto"/>
                        </div>
                        <div className="flex flex-col md:flex-row md:m-auto text-xxs font-extralight font-serif mt-2">
                            <div className="flex">
                               {locale.footer.copy.company}
                            </div>
                            <div className="flex ml-1">
                            &copy; {locale.footer.copy.rights}
                            </div>
                        </div>
                    </div>
                    <div className="flex  flex-col self-center text-sm md:mx-5">
                        <ul className="flex flex-row justify-between self-center md:space-x-1">
                            <li className="w-max leading-14 border-transparent border-b-4 hover:border-primary">
                                <Link to={`${locale.menu.remodeling}`}
                                    className="p-1 nav-link  no-underline    hover:duration-500">
                                    {locale.menu.text.remodeling}
                                </Link>
                            </li>
                            <li className="w-max leading-14 border-transparent border-b-4 hover:border-primary">
                                <Link to={`${locale.menu.build}`}
                                    className="  hover:duration-500 p-1 no-underline">
                                    {locale.menu.text.build}
                                </Link>
                            </li>
                            <li className="w-max leading-14 border-transparent border-b-4 hover:border-primary">
                                <Link to={`${locale.menu.design}`}
                                    className="  hover:duration-500 p-1  no-underline">
                                    {locale.menu.text.design}
                                </Link>
                            </li>
                        </ul>
                        <ul className="flex flex-row justify-between self-center md:justify-self-start  md:space-x-1">
                            <li className="w-max leading-14 border-transparent border-b-4 hover:border-primary">
                                <Link to={`${locale.menu.planning}`}
                                    className="  hover:duration-500 p-1  no-underline nav-menu-link_active">
                                    {locale.menu.text.planning}
                                </Link>
                            </li>
                            <li className="w-max leading-14 border-transparent border-b-4 hover:border-primary">
                                <Link to={`${locale.menu.about}`}
                                    className="  hover:duration-500 p-1  no-underline nav-menu-link_active">
                                    {locale.menu.text.about}
                                </Link>
                            </li>
                            <li className="w-max leading-14 border-transparent border-b-4 hover:border-primary">
                                <Link to={`${locale.menu.contact}`}
                                    className="  hover:duration-500 p-1  no-underline nav-menu-link_active">
                                    {locale.menu.text.contact}
                                </Link>
                            </li>
                        </ul>
                        <div className="flex  flex-col self-center text-xxs md:mx-5 mt-3">
                            <Link to={`${locale.menu.terms}`} >{locale.menu.text.terms}</Link>
                        </div>
                        <div className="flex  flex-col self-center text-xxs md:mx-5">
                            <Link to={`${locale.menu.politics}`} >{locale.menu.text.politics}</Link>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col flex-1 justify-between md:flex-row gap-4 md:gap-1  place-content-around">
                    <div className="flex flex-col self-center font-serif text-sm md:w-full md:ml-4 md:order-last ">
                        <a target="_blank" rel="noreferrer" href={locale.footer.contact.btn.action}  className="flex mt-4 lg:mt-0 lg:justify-end" >
                            <div className="flex flex-row items-center self-center justify-center bg-success w-full md:w-11/12 rounded-md px-4 md:px-1 py-2 text-md font-semibold font-serif m-auto md:m-0">
                                <div className="self-center md:justify-end md:mx-0">
                                    <StaticImage src="../images/wsp.png" alt="Obra Ya" layout="fixed"
                                        width={36}
                                        height={37}
                                        className="ml-3"
                                        imgClassName=""/>
                                </div>
                                <span className="self-center md:text-left pl-2 block md:justify-end md:mx-0 text-center font">
                                {locale.footer.contact.btn.text}
                                </span>
                            </div>
                        </a>
                    </div>
                    <div className="flex flex-col  self-center  text-xs md:text-sm text-center md:text-left md:w-full md:ml-5 font-sans">
                        <div className="flex self-center md:self-start">
                            <span className="font-semibold">
                                {locale.footer.contact.phone.label}
                                <span className="font-extralight ml-1">
                                {locale.footer.contact.phone.number}
                                </span>
                            </span>
                            
                        </div>
                        <div className="flex md:flex-col mt-3">
                            <span className="flex font-semibold"> {locale.footer.contact.email.label}
                                <span className="flex font-extralight ml-1">
                                {locale.footer.contact.email.mail} <span className="md:hidden ml-1">/</span>
                                </span>
                            </span>
                            <span className="flex font-extralight ml-1">
                            {locale.footer.contact.email.mail2}
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-row md:flex-col self-center justify-between font-serif text-xs font-extralight">
                        <div className="md:text-center md:mb-2 mt-1">
                            {locale.footer.contact.social}
                        </div>
                        <div className="flex flex-row ml-1">
                            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/Obrayaconstructora">
                                <StaticImage src="../images/facebook.svg" alt="Obra Ya" layout="fixed"
                                    width={28}
                                    height={28}
                                    className="my-auto mx-1 w-4/5 h-auto "
                                />
                            </a>
                            <a target="_blank" rel="noreferrer" href='https://www.instagram.com/obraya.com__/'>
                            <StaticImage src="../images/instagram.svg" alt="Obra Ya" layout="fixed"
                                width={28}
                                height={28}
                                className="my-auto mx-1 w-4/5 h-auto "
                                />
                            </a>
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/obra-ya/">
                                <StaticImage src="../images/linkedin.svg" alt="Obra Ya" layout="fixed"
                                    width={28}
                                    height={28}
                                    className="my-auto mx-1 w-4/5 h-auto "
                                    />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
