import React, { useState } from 'react'
import '../styles/global.css'
import Footer from './Footer'
import Header from './Header'
import { Context } from './Context'
import {IntlProvider} from 'react-intl'

import es from '../../lang/i18n/es.json'
import ec from '../../lang/i18n/ec.json'
import us from '../../lang/i18n/us.json'
import co from '../../lang/i18n/co.json'

// import flatten from 'flat'
const Layout = ({children, background}) => {
    
    let domain = 'co';
    let locale = es;
    let subdomain = 'es';
    let domainBD = 'COL';
    let currency = 'COP';
    if (typeof window !== `undefined`){
        domain = window.location.href;
    }
    if(domain.includes('ec')){
        subdomain = "ec";
        domainBD = 'ECU';
        locale = ec;
        currency = 'USD';
    }
    else if(domain.includes('us') || domain.includes('hauscontractor')){
        subdomain = "us";
        domainBD = 'USA';
        locale = us;
        currency = 'USD';
    }
    else{
        subdomain = "co";
        domainBD = "COL";
        locale = co;
        currency = 'COP';
    }

    const date = new Date();
    
    const [input, setInput] = useState({
        domain: domainBD,
        currency: currency,
        subdomain: subdomain,
        id_quote: 0,
        title:'',
        formato: false,
        pagina: 'remodelacion',
        email:'',
        type: {
            state: false,
            type: "vivienda"
        },
        calculator:{
            state: false,
            metrosTerreno: 0,
            ambienteTmp: "",
            ambienteMetrosTotal: 0,
            sinMetros: true,
            tipoAcabado: "economico",
            tipoCobro:"IVA",
            numAmbientes: 0,
            arrAmbientes: [],
            valor_inicial:0,
            valor_final:0,
            extras:{
                planos: false,
                virtual: false,
                tramites: false,
                materiales: false
            },
            cambio:{
                state: false,
                puertas: 0,
                ventanas: 0
            },
            pisos: 0,
        },
        tuvo_cotizacion: false,
        autorizacion_propiedad: "",
        fecha: date.getDate()  + "-" + (date.getMonth()+1) + "-" + date.getFullYear(),
        inicio: "",
        ubicacion: {
            pais:"",
            ciudad:"",
            calling_code: "",
            telefono:"",
            estrato:"",
            descripcion:"",
        },
        contacto:{
            tipo: '',
            nombre: '',
            apellido: '',
            email: '',
            mensaje: '',
            area: '',
            telefono: ''
        },
        reparacion:{
            electricista: false,
            plomero: false,
            cerrajero: false,
            telecomunicaciones: false,
            pintura: false,
            bricolaje: false,
            piscina: false,
            jardin: false,
            paneles: false,
            bodega: false,
            patio: false,
            impermeabilizacion: false,
            descripcion: "false"
        },
        locale: locale
    });
    let Child = children
    return (
        <Context.Provider value={{ input, setInput, locale }}>
            <IntlProvider locale={locale}
                key={locale}
                >
                <div className={`${background} bg-white bg-top-2 lg:bg-top bg-no-repeat bg-auto lg:min-h-full`}>
                    <Header locale={locale}/>
                    <main className="container m-auto h-auto">  
                        <Child locale={locale} input={input} />
                    </main>
                    <Footer locale={locale}/>
                </div>
            </IntlProvider>
        </Context.Provider>
    )
}
export default Layout
