import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"
const Header = ({ locale }) => {
  const [isOpen, setIsOpen] = useState(false)
  const genericHamburgerLine = `h-1/4 w-full my-0.5 rounded-full transition ease transform duration-300`
  const genericHamburgerMediumLine = `h-1/4 w-6/12 my-0.5 rounded-full transition ease transform duration-300`

  let detectMobil = false
  if (isBrowser) {
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      detectMobil = true
    } else {
      detectMobil = false
    }
    // console.log(detectMobil);
  }
  return (
    <header
      className={`${
        isOpen ? "bg-primary pt-0" : "bg-transparent pt-3"
      } w-full  left-0 h-16`}
    >
      <nav className="flex justify-between lg:m-auto lg:max-w-6xl">
        <Link
          to={`${locale.menu.home}`}
          href="#responsive-header"
          className={`${
            isOpen ? "invisible" : "visible"
          } logo nav-link ml-4 self-center flex-shrink-1 sm:flex-shrink-0`}
        >
          <StaticImage
            src="../images/logo.png"
            alt="Obra Ya"
            layout="constrained"
            width={182}
            height={57}
            className="m-auto w-4/5 h-auto lg:w-full"
            imgClassName="h-auto"
          />
        </Link>

        <button
          className={`${
            isOpen ? "z-20" : "z-auto"
          } flex flex-col h-8 w-9 m-auto mr-4 border-none justify-center items-center group lg:hidden`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div
            className={`${genericHamburgerLine} ${
              isOpen
                ? "rotate-45 translate-y-3  group-hover:opacity-100 bg-white"
                : " group-hover:opacity-100 bg-primary"
            }`}
          />
          <div
            className={`${genericHamburgerMediumLine} ${
              isOpen
                ? "opacity-0 bg-white"
                : " group-hover:opacity-100 bg-primary"
            }`}
          />
          <div
            className={`${genericHamburgerMediumLine} ${
              isOpen
                ? "opacity-0 bg-white"
                : " group-hover:opacity-100 bg-primary"
            }`}
          />
          <div
            className={`${genericHamburgerLine} ${
              isOpen
                ? "-rotate-45 -translate-y-3  group-hover:opacity-100 bg-white"
                : " group-hover:opacity-100 bg-primary"
            }`}
          />
        </button>
        <ul
          className={`${
            isOpen
              ? "visible z-10 bg-primary text-white pb-10"
              : "invisible bg-transparent text-secondary"
          }
                rounded-b-200
                     lg:visible  
                     w-full 
                     lg:w-auto 
                     flex 
                     flex-col 
                     lg:flex-wrap
                     lg:flex-row
                     fixed 
                     top-2
                     lg:top-auto 
                     lg:relative 
                     items-center 
                     mr-2 
                     list-none 
                     p-0 
                     lg:h-auto 
                     delay-300 
                     transform  
                     translate-x-100 
                     self-center 
                     text-xl
                     lg:text-base
                     font-normal
                     leading-10
                     lg:leading-none
                     lg:normal 
                     lg:space-x-3
                     `}
        >
          {!detectMobil ? (
            <li className="sm:bg-green p-3 rounded-lg group">
              <div className=" inline-block">
                <button className="outline-none border-none focus:outline-none border px-3  rounded-sm flex items-center min-w-32">
                  <span className="pr-1 hover:font-semibold flex-1 text-white">
                    {locale.menu.text.cotiza}
                  </span>
                  <span>
                    <svg
                      className="fill-current h-4 w-4 transform group-hover:-rotate-180
                                                                                                                                            transition duration-150 ease-in-out text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </span>
                </button>
              </div>
              <ul
                className="bg-white border rounded-b-lg transform scale-0 group-hover:scale-100 absolute
                                                                                                        transition duration-150 ease-in-out origin-top min-w-32 mt-3 -left-4 text-center"
              >
                <li className="rounded-sm px-3 py-4 hover:bg-gray-100">
                  <Link
                    to={`${locale.menu.remodeling}`}
                    activeClassName="text-gray lg:text-primary"
                    className="p-1 nav-link  no-underline hover:text-primary   hover:duration-500 border-transparent border-b-5 rounded-br-5"
                  >
                    {locale.menu.text.remodeling}
                  </Link>
                </li>
                <li className="rounded-sm px-3 py-4 hover:bg-gray-100">
                  <Link
                    to={`${locale.menu.build}`}
                    activeClassName="text-primary"
                    className="p-1 nav-link  no-underline hover:text-primary"
                  >
                    {locale.menu.text.build}{" "}
                  </Link>
                </li>

                <li className="rounded-sm px-3 py-4 hover:bg-gray-100">
                  <Link
                    to={`${locale.menu.design}`}
                    activeClassName="text-primary"
                    className="p-1 nav-link  no-underline hover:text-primary   hover:duration-500 border-transparent border-b-5 rounded-br-5"
                  >
                    {locale.menu.text.design}{" "}
                  </Link>
                </li>
                <li className="rounded-sm px-3 py-4 hover:bg-gray-100">
                  <Link
                    to={`${locale.menu.planning}`}
                    activeClassName="text-primary"
                    className="p-1 nav-link  no-underline hover:text-primary   hover:duration-500 border-transparent border-b-5 rounded-br-5"
                  >
                    {locale.menu.text.planning}{" "}
                  </Link>
                </li>
              </ul>
            </li>
          ) : (
            ""
          )}

          {detectMobil ? (
            <li className="rounded-sm px-3 py-2 hover:bg-gray-100">
              <Link
                to={`${locale.menu.remodeling}`}
                activeClassName="text-gray"
                className="p-1 nav-link  no-underline hover:text-primary   hover:duration-500 border-transparent border-b-5 rounded-br-5"
              >
                {locale.menu.text.remodeling}{" "}
              </Link>
            </li>
          ) : (
            ""
          )}
          {detectMobil ? (
            <li className="rounded-sm px-3 py-2 hover:bg-gray-100">
              <Link
                to={`${locale.menu.build}`}
                activeClassName="text-gray"
                className="p-1 nav-link  no-underline hover:text-primary"
              >
                {locale.menu.text.build}{" "}
              </Link>
            </li>
          ) : (
            ""
          )}
          {detectMobil ? (
            <li className="rounded-sm px-3 py-2 hover:bg-gray-100">
              <Link
                to={`${locale.menu.design}`}
                activeClassName="text-gray"
                className="p-1 nav-link  no-underline hover:text-primary   hover:duration-500 border-transparent border-b-5 rounded-br-5"
              >
                {locale.menu.text.design}{" "}
              </Link>
            </li>
          ) : (
            ""
          )}
          {detectMobil ? (
            <li className="rounded-sm px-3 py-2 hover:bg-gray-100">
              <Link
                to={`${locale.menu.planning}`}
                activeClassName="text-gray"
                className="p-1 nav-link  no-underline hover:text-primary   hover:duration-500 border-transparent border-b-5 rounded-br-5"
              >
                {locale.menu.text.planning}{" "}
              </Link>
            </li>
          ) : (
            ""
          )}
          <li className="lg:m-auto lg:mr-1 w-max leading-14 mt-2 lg:mt-0 px-3 py-2 md:p-3">
            <Link
              to={`${locale.menu.about}`}
              activeClassName="text-gray md:text-primary border-primary"
              className="p-1 nav-link  no-underline hover:text-primary   hover:duration-500 border-transparent border-b-5 rounded-br-5 hover:border-primary"
            >
              {locale.menu.text.about}{" "}
            </Link>
          </li>
          {/* <li className="lg:m-auto lg:mr-1 w-max leading-14 mt-2 lg:mt-0 px-3 py-2 md:p-3">
                    <a href="/blog"
                        className="p-1 nav-link  no-underline hover:text-primary   hover:duration-500 border-transparent border-b-5 rounded-br-5 hover:border-primary">
                        Blog
                    </a>
                </li> */}
          <li className="lg:m-auto lg:mr-1 w-max leading-14 mt-2 lg:mt-0 px-3 py-2 md:p-3">
            <Link
              to={`${locale.menu.contact}`}
              activeClassName="text-gray md:text-primary border-primary"
              className="p-1 nav-link  no-underline hover:text-primary   hover:duration-500 border-transparent border-b-5 rounded-br-5 hover:border-primary"
            >
              {locale.menu.text.contact}{" "}
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  )
}
export default Header
